import { AxiosError } from 'axios';
import { Store } from 'effector';
import { useStore } from 'effector-react';
import { DependencyList, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { getDraftSaveStorage, SaveDraftEvent } from '../store/draft';

const SAVED_DRAFT_DELAY = 30000;

type SaveDraftCallback<DraftType> = (draftValues: DraftType) => void;
type DraftAPI<DraftType> = {
  isSaved: boolean;
  isPending: boolean;
  isDebounced: () => boolean;
  fetchSaveEffect: SaveDraftEvent<DraftType>;
  destroySaveDraft: () => void;
  cancelSaveDraft: () => void;
  flushSaveDraft: () => void;
  setVisibilityEvent: (visibility: boolean) => void;
  store: Store<DraftType | null>;
};

export type UseDraftSaveParams<DraftType> = {
  delay?: number;
  onSaveDraftEffect: SaveDraftEvent<DraftType>;
  onSuccessDraftEvent?: (draft: DraftType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onErrorDraftEvent?: (e: AxiosError<any>) => void;
  deps?: DependencyList[];
};
export type UseDraft<DraftType> = { draft: DraftAPI<DraftType>; setDraft: SaveDraftCallback<DraftType> };

export const useDraftSave = <Draft>(params: UseDraftSaveParams<Draft>): UseDraft<Draft> => {
  const {
    delay = SAVED_DRAFT_DELAY,
    onErrorDraftEvent,
    onSuccessDraftEvent,
    onSaveDraftEffect,
    deps = [],
  } = params;

  const {
    saveDraftEffect,
    savedDraftStore,
    savedDraftNotifyVisibilityStore,
    setSavedDraftEvent,
    setSavedDraftVisibilityEvent: setVisibilityEvent,
  } = useMemo(() => getDraftSaveStorage<Draft>({ onSaveDraftEffect }), [onSaveDraftEffect]);

  const onSuccessDraft = (draft: Draft) => {
    setSavedDraftEvent(draft);
    setVisibilityEvent(true);

    if (onSuccessDraftEvent) {
      onSuccessDraftEvent(draft);
    }
  };

  const savedDraft = useStore(savedDraftStore);
  const saveDraft = useCallback<SaveDraftCallback<Draft>>(
    (draftValues) => {
      saveDraftEffect(draftValues).then(onSuccessDraft).catch(onErrorDraftEvent);
    },
    [savedDraft, ...deps],
  );
  const saveDraftDebounced = useDebouncedCallback(saveDraft, delay, { maxWait: delay });
  const saveDraftDebouncedMemoized = useMemo(() => saveDraftDebounced, [saveDraft]);
  const setDraft = useCallback(
    (values: Draft) => saveDraftDebouncedMemoized(values),
    [saveDraftDebouncedMemoized],
  );
  const destroySaveDraft = useCallback(() => {
    setSavedDraftEvent(null);
    setVisibilityEvent(false);
    saveDraftDebouncedMemoized.cancel();
  }, []);

  const isPending = useStore(saveDraftEffect.pending);
  const isSaved = useStore(savedDraftNotifyVisibilityStore);

  const draft = useMemo(
    () => ({
      isSaved,
      isPending,
      isDebounced: saveDraftDebouncedMemoized.isPending,
      setVisibilityEvent,
      store: savedDraftStore,
      fetchSaveEffect: saveDraftEffect,
      cancelSaveDraft: saveDraftDebouncedMemoized.cancel,
      flushSaveDraft: saveDraftDebouncedMemoized.flush,
      destroySaveDraft,
    }),
    [
      isSaved,
      isPending,
      saveDraftDebouncedMemoized,
      savedDraftStore,
      saveDraft,
      setVisibilityEvent,
      saveDraftEffect,
      destroySaveDraft,
    ],
  );

  return { draft, setDraft };
};

export type SaveDraftEffect<T> = SaveDraftEvent<T>;
