import addHours from 'date-fns/addHours';
import set from 'date-fns/set';
import startOfHour from 'date-fns/startOfHour';

export const getNearestHourInterval = (date: Date): [Date, Date] => {
  const getSelectedDateWithCurrentTime = (selectedDate: Date) => {
    const currentDate = new Date();

    return set(selectedDate, {
      hours: currentDate.getHours(),
      minutes: currentDate.getMinutes(),
    });
  };

  const sinceDate = addHours(startOfHour(getSelectedDateWithCurrentTime(date)), 1);
  const tillDate = addHours(sinceDate, 1);

  return [sinceDate, tillDate];
};
