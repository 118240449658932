import type { AttachmentDetail } from './attachment.model';
import { EventFormatType } from './event.model';
import type {
  FileStorageEntryId,
  FileStorageImage,
  FileStorageListEntryModel,
  FileStorageObjectModel,
} from './file.model';
import { MeetingRoomFreeSlot, MeetingScales } from './meetings.model';
import { SitesAllowedPermission } from './permissions.model';
import { SiteId } from './sites.model';
import type { UserIdModel, UserModel, UserPositionModel } from './user.model';

export enum CalendarUserStatus {
  NeedAction = 'need-action',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Maybe = 'maybe',
  NotAttend = 'not_attend',
}

export enum CalendarItemStatus {
  Past = 'past',
  Future = 'future',
  Maybe = 'maybe',
}

export enum CalendarEventType {
  Meeting = 'meeting',
  Task = 'task',
  Learn = 'learn',
  Activity = 'activity',
}

export enum CalendarViewType {
  Week = 'week',
  Month = 'month',
  Day = 'day',
}

export enum CalendarFrequency {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum CalendarSourceType {
  Exchange = 'exchange',
  Sps = 'sps',
  Iasup = 'iasup',
}

export enum CalendarRequiredMembers {
  All = 'all',
  RequiredOnly = 'required_only',
}

export type CalendarWeekDays = 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su';

export type CalendarWeekDaysMap = Record<CalendarWeekDays, boolean>;

export type CalendarRepeatRule = {
  days: CalendarWeekDaysMap;
  everyWeek: number;
  beforeDate: string;
};

export type CalendarAttendee = {
  userId: string;
  status: CalendarUserStatus;
  fullName: string;
  image: string;
  mailto: string;
  position?: UserPositionModel;
};

export type CalendarAttendeeToAdd = {
  userId: string;
  status: CalendarUserStatus;
  fullName?: string;
  image?: string;
  mailto: string;
  keycloakUser?: UserModel['keycloakUser'];
  position?: UserPositionModel;
  isActive?: boolean;
};

export type CalendarAttendeeList = CalendarAttendee[];
export type CalendarAttendeeToAddList = CalendarAttendeeToAdd[];

export type CalendarEventModelId = string;

export interface CalendarEventModel {
  id: CalendarEventModelId;
  calendarId: string;
  organizer: CalendarAttendee;
  summary: string;
  since: string;
  till: string;
  isFullDay?: boolean;
  isDeleted: boolean;
  repeat?: boolean;
  format: EventFormatType;
  repeatRule?: CalendarRepeatRule;
  firstAttendees?: CalendarAttendee[];
  eventType: CalendarEventType;
  sourceType: CalendarSourceType;
  description?: string;
  place?: string;
  myStatus: CalendarUserStatus;
  link?: string;
  image: FileStorageImage;
  isUserParticipate: boolean;
  externalId?: string;
  attachmentDetails: AttachmentDetail[];
  groupName?: string;
  slug?: string;
  // TODO: Решить с бекендом, нужен ли формат string, если используется только number ('1')
  meetingRoomId?: string | null;
  meetingRoom?: MeetingRoomFreeSlot;
  fileStorageObjects: FileStorageListEntryModel[];
  memberTotalUids: UserIdModel[];
  permissions: SitesAllowedPermission[];
}

export type CalendarReducedEvent = Record<string, CalendarEventModel[]>;

export type CalendarFreeSlot = {
  since: string;
  till: string;
  meetingRoomsCount?: number;
};

export type CalendarBusyInterval = {
  calendarEventId?: CalendarEventModelId;
  since: string;
  till: string;
  status: CalendarUserStatus;
};

export type CalendarBusySlots = {
  userId: string;
  intervals: CalendarBusyInterval[];
};

export type CalendarEventDates = {
  since: string;
  till: string;
};

export interface CalendarEventEditFormValuesType {
  id?: string;
  summary?: string;
  dateTime?: [Date, Date];
  isFullDay?: boolean;
  repeat?: boolean;
  format?: EventFormatType;
  repeatRule?: CalendarRepeatRule;
  repeatRuleJson?: CalendarRepeatRule;
  attendees?: CalendarAttendeeToAdd[];
  description?: string;
  link?: string;
  place?: string;
  allowInvitation?: string;
  meetingScale?: MeetingScales;
  meetingRoomId?: string;
  isEdit?: boolean;
  organizer?: CalendarAttendee;
  firstAttendees?: CalendarAttendee[];
  fileStorageObjects?: FileStorageObjectModel[];
}

export type CalendarListItem = {
  id: string;
  name: string;
  sourceType: CalendarSourceType;
};

export interface CalendarEventCreateParams {
  summary: string;
  since: string;
  till: string;
  isFullDay?: boolean;
  repeat?: boolean;
  format?: EventFormatType;
  repeatRule?: CalendarRepeatRule;
  repeatRuleJson?: CalendarRepeatRule;
  attendees?: CalendarAttendeeToAdd[];
  eventType: CalendarEventType;
  sourceType: CalendarSourceType;
  description?: string;
  place?: string;
  link?: string;
  meetingRoomId?: string;
  fileStorageObjects: FileStorageEntryId[];
  siteId?: SiteId;
}

interface CalendarEventParams {
  id: CalendarEventModelId;
  attendeesToAdd?: CalendarAttendeeToAdd[];
  attendeesToRemove?: CalendarEventModelId[];
}

export type CalendarEventPatchParams = CalendarEventParams;

export interface CalendarEventUpdateParams extends CalendarEventParams {
  summary?: string;
  since?: string;
  till?: string;
  isFullDay?: boolean;
  repeatRule?: CalendarRepeatRule;
  eventType?: CalendarEventType;
  description?: string;
  place?: string;
  link?: string;
  meetingRoomId?: string | null;
  siteId?: SiteId;
}

export interface CalendarStatusUpdate {
  accepted?: CalendarEventModelId[];
  rejected?: CalendarEventModelId[];
  maybe?: CalendarEventModelId[];
}

export interface ParticipateStatus {
  isParticipates: boolean;
}
