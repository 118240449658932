import { ServiceRolesGroupDataModel } from '../roles';
import { BlogId, FileStorageDisplayType } from './blog.model';
import { CommentCountModel } from './comment.model';
import type { Dictionaries } from './dictionary.model';
import type {
  CreateIssuerDateModel,
  CreateIssuerIdDateModel,
  UpdateIssuerDateModel,
} from './issuerDate.model';
import type { ProjectId } from './projects';
import type { ReactableModel } from './reactions.model';
import { SiteId, SiteSlug } from './sites.model';
import type { BaseTag } from './tags.model';
import type { UserIdModel, FullNameModel, UserAvatarModel, UserModel } from './user.model';

export type FileId = number;
export enum FilesEntryTypes {
  Blog = 'blog',
}

export enum FileStorageContextServiceTypes {
  Blogs = 'blogs',
  Profile = 'profile',
  Projects = 'projects',
  FileStorage = 'filestorage',
  Sites = 'sites',
}

export type AdditionalFileInfoImageMimeType = string;
export type FileStorageEntryFavoriteId = number;
export type FileStorageEntryId = string;
export type FileStorageSlug = string;
export type FileStorageEntryVersionId = number;
export type FileStorageEntryRevision = number;
export type FileStorageCategoryId = number;
export type FileStorageEntryUrl = string;
export type FileStorageRoleId = number;

// todo разделить модель файлов на v1 и v2
export interface FileModel {
  id: FileId;
  fileUrl: string;
  attachmentFileUrl: string;
  name: string;
  size?: number;
  fileSize?: number;
  createdAt?: string;
  uploadedAt?: string;
  updated?: string;
  updatedAt?: string;
  entryType?: FilesEntryTypes;
  entryId?: number;
  author?: UserModel;
  parentFolder?: number;
  storageObject: FileStorageEntryId;
}

export interface FileWithRevisionsModel extends FileModel {
  revisions: FileModel[];
}

export interface FileFolderModel {
  folderId: number;
  parentId: number | null;
  name: string;
  files: FileModel[];
  modelName: string;
  objectId: number;
  children: FileFolderModel[];
}

export interface FileToUploadModelWithoutData {
  id: string;
  // бекенд принимает именно в таком виде это поле
  filename: string;
  rawFile: File;
}

export interface FileToUploadModel extends FileToUploadModelWithoutData {
  /** спрасенный контент файла */
  data: string;
}

export type FileOrFolder = FileFolderModel | FileModel;
export enum FileStorageEntryType {
  Folder = 0,
  File = 1,
}

export type AdditionalFileInfoImageData = {
  format: string;
  formatDescription: string;
  height: number;
  mimeType: string;
  mode: string;
  width: number;
  isAnimated: boolean;
};

export interface AdditionalFileInfoVideoData extends Pick<AdditionalFileInfoImageData, 'width' | 'height'> {
  audioFound?: boolean;
  audioFps?: number;
  duration?: number;
  videoFound?: boolean;
  videFps?: number;
  videoDuration?: number;
  videoNframes?: number;
  videoRotation?: number;
  videoSize: [number, number];
}

export type AdditionalFileInfo = {
  imageData?: AdditionalFileInfoImageData;
  videoData?: AdditionalFileInfoVideoData;
  mimeType: AdditionalFileInfoImageMimeType;
};

export interface FileStorageIssuerUserModel
  extends Pick<UserAvatarModel, 'avatar'>,
    Pick<UserModel, 'id' | keyof FullNameModel> {
  keycloakId: UserIdModel;
}

interface BaseFileStorageEntryModel
  extends Partial<CreateIssuerDateModel<FileStorageIssuerUserModel>>,
    Partial<UpdateIssuerDateModel<FileStorageIssuerUserModel>> {
  id: FileStorageEntryId;
  name: string;
  shortName: string;
  type: FileStorageEntryType;
  slug?: FileStorageSlug;
  file?: FileStorageEntryUrl;
  additional?: AdditionalFileInfo;
}

export type FileStorageBlogContextModel = {
  blogId: BlogId;
  blogSlug: string;
  service: FileStorageContextServiceTypes.Blogs;
};

export type FileStorageProfileContextModel = {
  userId: UserIdModel;
  service: FileStorageContextServiceTypes.Profile;
};

export type FileStorageProjectContextModel = {
  projectId: ProjectId;
  service: FileStorageContextServiceTypes.Projects;
};

export type FileStorageContextModel = {
  service: FileStorageContextServiceTypes.FileStorage;
};

export type FileStorageSitesContextModel = {
  service: FileStorageContextServiceTypes.Sites;
  siteSlug: SiteSlug;
  siteId: SiteId;
};

export type FileStorageListEntryContext =
  | FileStorageContextModel
  | FileStorageBlogContextModel
  | FileStorageProfileContextModel
  | FileStorageProjectContextModel
  | FileStorageSitesContextModel;

type SiteFileStorage = {
  id: FileStorageEntryId;
  name: string;
  slug: FileStorageSlug;
};

export interface FileStorageListEntryModel<TContext = FileStorageListEntryContext>
  extends BaseFileStorageEntryModel,
    Required<ReactableModel>,
    CommentCountModel {
  size: number;
  foldersCount: number;
  filesCount: number;
  parent: FileStorageEntryId;
  objectViewsCount: number;
  permissionsV2?: FileStoragePermissions[];
  context?: TContext;
  additional?: AdditionalFileInfo;
  description?: string;
  siteFilestorage?: SiteFileStorage;
}

export type FileStorageListEntry = Omit<
  FileStorageListEntryModel,
  'objectViewsCount' | 'commentsCount' | 'reactions'
>;

export type FileStorageEntryMove = BaseFileStorageEntryModel & Pick<FileStorageListEntryModel, 'parent'>;

export interface FileStorageEntryModel extends BaseFileStorageEntryModel {
  parent: FileStorageEntryId;
  foldersCount?: number;
  filesCount?: number;
}

export type FileStorageEntryHierarchyModel = Pick<BaseFileStorageEntryModel, 'id' | 'name'>;

export type FileStorageEntryCreatorsModel = FullNameModel &
  Required<Pick<UserModel, 'id' | 'keycloakId' | 'avatar' | 'isActive'>>;

export type BaseFileStorageEntryVersion = {
  revision: FileStorageEntryRevision;
  versionId: FileStorageEntryVersionId;
};

export interface FileStorageEntryInfoModel<
  Tags extends BaseTag = Dictionaries.Dict,
  TC = FileStorageListEntryContext,
> extends CreateIssuerDateModel<FileStorageIssuerUserModel>,
    UpdateIssuerDateModel<FileStorageIssuerUserModel>,
    BaseFileStorageEntryVersion,
    Required<ReactableModel>,
    Pick<FileStorageListEntryModel<TC>, 'context'>,
    Pick<FileStorageEntryModel, 'parent'> {
  id: FileStorageEntryId;
  name: string;
  shortName: string;
  file: FileStorageEntryUrl;
  size: number;
  description: string;
  revisionsCount: number;
  objectViewsCount: number;
  tags: Tags[];
  categories: Tags[];
  isFavorite: boolean;
  permissionsV2?: FileStoragePermissions[];
  additional?: AdditionalFileInfo;
  extensions: FileStorageFolderExtensionsModel;
  filesCount?: number;
  foldersCount?: number;
  slug?: FileStorageSlug;
  fileStorageDisplayType: FileStorageDisplayType;
}

export interface FileStorageEntryVersionModel
  extends CreateIssuerDateModel<FileStorageIssuerUserModel>,
    BaseFileStorageEntryVersion,
    Pick<FileStorageEntryInfoModel, 'name' | 'size' | 'file'>,
    CommentCountModel {
  isDeleted: boolean;
}

export interface FileStorageFileModel
  extends BaseFileStorageEntryModel,
    Partial<Pick<BaseFileStorageEntryVersion, 'revision'>> {
  size: number;
  description?: string;
  tags?: string[];
  categories?: number[];
  parent: FileStorageEntryId;
  file: FileStorageEntryUrl;
}

export interface PreloadedFileModel
  extends Pick<BaseFileStorageEntryVersion, 'versionId'>,
    Pick<FileStorageFileModel, 'name' | 'size' | 'shortName'>,
    Pick<CreateIssuerIdDateModel, 'createdAt'> {
  storageObject: FileStorageEntryId;
  fileUrl: FileStorageEntryUrl;
}

export type FileStorageCategoryModel = {
  id: FileStorageCategoryId;
  name: string;
  rootFolder: FileStorageEntryId;
};

type FileStorageHistoryActionType = string;
type FileStorageHistoryObjectType = string;
type FileStorageHistorySummary = {
  summary: string;
};

interface FileStorageHistoryObjectModel extends FileStorageHistorySummary {
  value: string | number;
  type: FileStorageHistoryObjectType;
  meta: Record<string, unknown>;
}

export interface FileStorageEntryHistoryModel<T = FileStorageHistoryObjectModel>
  extends Pick<CreateIssuerIdDateModel, 'createdAt'>,
    FileStorageHistorySummary {
  type: FileStorageHistoryActionType;
  actor: FileStorageIssuerUserModel | null;
  object: T;
}

export interface FileStorageEntryFavoriteModel
  extends CreateIssuerDateModel<FileStorageIssuerUserModel>,
    Pick<PreloadedFileModel, 'storageObject'> {
  id: FileStorageEntryFavoriteId;
}

export interface FileStorageAttachmentEntityModel
  extends Omit<BaseFileStorageEntryModel, 'type'>,
    Pick<FileStorageFileModel, 'description' | 'parent' | 'size'> {
  version: FileStorageEntryVersionId;
  file: FileStorageEntryUrl;
}

export type FileStorageCropImageModel = Omit<FileStorageEntryInfoModel, 'type'>;

export type FileStorageImage = {
  fileStorageImageUrl: FileStorageEntryUrl | null;
  fileStorageImageId: FileStorageEntryId | null;
};

export interface FileStorageObjectModel
  extends Omit<BaseFileStorageEntryModel, 'file'>,
    Pick<FileStorageFileModel, 'description' | 'parent' | 'size' | 'file'> {
  additional?: AdditionalFileInfo;
  version: FileStorageEntryVersionId;
}

export enum FileStorageFolderPermissionTypes {
  Anyone = 'anyone',
  Some = 'some',
  Nobody = 'nobody',
}

export enum FileStorageFolderUserRoles {
  Admin = 'admin',
  ReadOnlyFullOwner = 'read-only-full-owner',
}

export interface FileStorageFolderUserRoleModel
  extends Pick<FileStorageIssuerUserModel, 'firstName' | 'lastName' | 'avatar' | 'patronymic'> {
  role: FileStorageFolderUserRoles;
  userUid: UserIdModel;
}

export enum FileStorageFolderVideoExtensions {
  MP4 = '.mp4',
  MPEG = '.mpeg',
  AVI = '.avi',
}

export enum FileStorageFolderImageExtensions {
  JPEG = '.jpeg',
  PNG = '.png',
  TIFF = '.tiff',
  GIF = '.gif',
  BMP = '.bmp',
}

export type FileStorageFolderExtensionsModel = {
  video: FileStorageFolderVideoExtensions[];
  image: FileStorageFolderImageExtensions[];
};

export type FileStorageFolderUserRolesModel = {
  defaultRole: FileStorageFolderUserRoles;
  userRoles: FileStorageFolderUserRoleModel[];
};

export enum FileStoragePermissionsRoleName {
  LocalSiteReader = 'local_site_reader',
  LocalSiteEditor = 'local_site_editor',
}

export interface FileStoragePermissionsRoleModel {
  id: FileStorageRoleId;
  name: FileStoragePermissionsRoleName;
  displayName: string;
  subscope: string;
  isSystemDefined: boolean;
  isInstanceSpecific: boolean;
  permissions: FileStoragePermissionsRolesPermission[];
  description: FileStoragePermissionsRolesDescription;
  users: UserIdModel[];
  groups: string[]; // TODO: B2BCORE-12345: тут должен быть BlogId[]
  isOverride: boolean;
}

export interface FileStoragePermissionsRolesModel
  extends Omit<FileStoragePermissionsRoleModel, 'users' | 'groups'> {
  users: UserModel[];
  groups: ServiceRolesGroupDataModel[];
}

interface FileStoragePermissionsRolesDescription {
  file: string;
  folder: string;
}

export enum FileStoragePermissions {
  FileRead = 'filestorage::file::read',
  FileCreate = 'filestorage::file::create',
  FileReaction = 'filestorage::file::reaction',
  FileComment = 'filestorage::file::comment',
  FolderRead = 'filestorage::folder::read',
  FileEdit = 'filestorage::file::edit',
  FileMove = 'filestorage::file::move',
  FileDelete = 'filestorage::file::delete',
  FileManageAccess = 'filestorage::file::manage_access',
  VersionCreate = 'filestorage::version::create',
  FolderCreate = 'filestorage::folder::create',
  FolderEdit = 'filestorage::folder::edit',
  FolderMove = 'filestorage::folder::move',
  FolderDelete = 'filestorage::folder::delete',
  FolderManageAccess = 'filestorage::folder::manage_access',
}

interface FileStoragePermissionsRolesPermission {
  id: FileStorageRoleId;
  name: string;
  fullName: FileStoragePermissions;
  displayName: string;
  subscope: FileStoragePermissionsRolesSubscope;
}

export enum FileStoragePermissionsRolesSubscope {
  File = 'file',
  Folder = 'folder',
  Version = 'version',
}
