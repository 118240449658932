import type { Rule } from '@vkph/ui';

import { EditorValue } from '../../types/editor';
import { getPlainTextFromHtml } from '../markupContent';

export const MUST_BE_FILLED_MESSAGE = 'Поле должно быть заполнено';
export const NO_EMPTY_RULE_MESSAGE = 'Поле не должно быть заполнено пустым тегом';

export const requiredRule: Rule = {
  required: true,
  whitespace: true,
  message: MUST_BE_FILLED_MESSAGE,
};

/** @deprecated должен быть заменен на requiredDateTimeRangeRule */
export const requiredDateOrTimeRule: Rule = {
  type: 'object',
  required: true,
  whitespace: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const requiredDateTimeRangeRule: Rule = {
  type: 'array',
  required: true,
  whitespace: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const requiredRuleSelect: Rule = {
  required: true,
  message: 'Выберите один из доступных вариантов',
};

export const requiredRuleMultiSelect: Rule = {
  required: true,
  message: 'Выберите доступные варианты',
};

export const requiredRuleWithWhitespace: Rule = {
  required: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const requiredEditor: Rule = {
  validator: (_, value?: EditorValue) => {
    return value?.data?.length && String(value.data).replaceAll('&nbsp;', '').trim()
      ? Promise.resolve()
      : Promise.reject();
  },
  required: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const noEmptyHtmlRule: Rule = {
  validator: (_, value?: string) =>
    value && value.trim().length !== 0 && (getPlainTextFromHtml(value) || '').trim().length === 0
      ? Promise.reject()
      : Promise.resolve(),
  message: NO_EMPTY_RULE_MESSAGE,
};

export const getNoEmptyHtmlRuleMessage = (message = NO_EMPTY_RULE_MESSAGE): Rule => ({
  ...noEmptyHtmlRule,
  message,
});
