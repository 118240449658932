import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import humps from 'humps';

import { endpoints } from '../../endpoints';
import { FileLogoVariants } from '../../types/models';
import { abstractStorageFactory } from '../../utils/effector';
import {
  deleteHeaderLogoImage,
  FileSchemeLogoResponse,
  HeaderLogoImageData,
  HeaderLogoImageModel,
  updateHeaderLogoImage,
} from './api';

export const getHeaderLogoImageStorage = () => {
  const defaultValue: Record<FileLogoVariants, null> = {
    [FileLogoVariants.EmptyState]: null,
    [FileLogoVariants.ImportantNews]: null,
    [FileLogoVariants.LogoDesktop]: null,
    [FileLogoVariants.LogoEmail]: null,
    [FileLogoVariants.LogoMobile]: null,
    [FileLogoVariants.PortalFavicon]: null,
    [FileLogoVariants.PortalHeader]: null,
    [FileLogoVariants.UserAvatar]: null,
  } as const;

  const dataMapper = (data: FileSchemeLogoResponse): HeaderLogoImageModel => {
    const result: HeaderLogoImageModel = { ...defaultValue };

    data.forEach(({ type, fileStorageObject }) => {
      result[humps.camelize(type)] = fileStorageObject;
    });

    return result;
  };

  const storage = abstractStorageFactory<FileSchemeLogoResponse, HeaderLogoImageModel, HeaderLogoImageModel>({
    endpointBuilder: endpoints.pages.image,
    defaultValue,
    cancelPendingRequestOnFetch: true,
    dataMapper,
  });

  const changeHeaderLogoImageEffect = createEffect<HeaderLogoImageData, HeaderLogoImageModel, AxiosError>(
    (params) => {
      let effect;

      const updateList = Object.keys(params);

      if (Object.values(FileLogoVariants).some((variant) => params[variant])) {
        const update = updateList.map((variant) => ({
          type: humps.decamelize(variant) as FileLogoVariants,
          fileStorageObject: params[variant as FileLogoVariants],
        }));

        effect = updateHeaderLogoImage(update);
      } else {
        effect = deleteHeaderLogoImage();
      }

      return effect.then(({ data }) => dataMapper(data));
    },
  );

  storage.store.on(changeHeaderLogoImageEffect.done, (state, { result }) => ({
    ...state,
    data: result,
  }));

  return { storage, changeHeaderLogoImageEffect };
};

export const headerLogoImageStorage = getHeaderLogoImageStorage();
export type GetHeaderLogoImageStorage = ReturnType<typeof getHeaderLogoImageStorage>;
