import { CommentCountModel } from './comment.model';
import {
  FileStorageEntryId,
  FileStorageEntryInfoModel,
  FileStorageListEntryContext,
  FileStorageListEntryModel,
} from './file.model';
import { GroupId } from './groups.model';
import {
  CreateIssuerDateModel,
  CreateIssuerIdDateModel,
  UpdateIssuerDateModel,
  UpdateIssuerIdDateModel,
} from './issuerDate.model';
import { OrganizationId } from './organization.model';
import { SitesAllowedPermission } from './permissions.model';
import { ReactableModel } from './reactions.model';
import { SiteId } from './sites.model';
import { UserModel, UserProfileModel } from './user.model';

export type ListId = string;
export type ListSlug = string;
export type ListColumnId = string;
export type ListRowId = string;
export type ListGroupId = string;
type DictionaryCellValueId = string;

export enum ListStatus {
  Published = 'published',
  Draft = 'draft',
}

export enum ListServiceType {
  List = 'list',
}

export enum ListColumnFieldType {
  Text = 'text',
  Numeric = 'numeric',
  Boolean = 'boolean',
  User = 'user',
  Datetime = 'datetime',
  Enum = 'enum',
  Dictionary = 'dictionary',
  Hyperlink = 'hyperlink',
  File = 'file',
}

export enum DictionaryType {
  Position = 'position',
  Organization = 'organization',
  Department = 'department',
}

export type ListNotificationsSettings = {
  adminsAndEditors: boolean;
  listUsers: boolean;
};

export type ListColumnFieldOptions = {
  default: ListCellModelValues;
  maxLength: number;
  groupValues: boolean;
  range?: {
    minValue: number;
    maxValue: number;
  };
  folderId?: FileStorageEntryId;
  folder?: FileStorageListEntryModel<FileStorageListEntryContext>;
  trueValueName?: string;
  falseValueName?: string;
  onlyDate?: boolean;
  choices?: string[];
  dictionaryType?: DictionaryType;
  organizationId?: OrganizationId;
  departmentId?: GroupId;
};

export type DictionaryCellValue = {
  type: DictionaryType;
  value: DictionaryCellValueId;
  label: string;
};

export type DictionaryCellModelValue = {
  id: string;
  dictionaryType: DictionaryType;
  dictionaryValueId: DictionaryCellValueId;
  dictionaryValue: string;
};

type DictionaryRequestCellValue = {
  dictionaryType: DictionaryType;
  dictionaryValuesIds: string[];
};

export interface UserCellValue extends Pick<UserModel, 'id' | 'avatar'> {
  name: string;
}

export type FileCellValue = Pick<FileStorageListEntryModel, 'id' | 'file' | 'name' | 'createdAt' | 'size'>;

export type ListCellFileModel = {
  id: FileStorageEntryId;
  name: string;
  file: string;
  fileData?: FileStorageEntryInfoModel;
};

type BaseCellValues = number | string | boolean | undefined | string[];
type ListBooleanCellValueModel = [boolean, string];

export type ListCellValues =
  | BaseCellValues
  | UserCellValue
  | DictionaryCellValue[]
  | DictionaryRequestCellValue
  | FileCellValue;

export type ListCellModelValues =
  | BaseCellValues
  | ListBooleanCellValueModel
  | UserModel
  | DictionaryCellModelValue[]
  | DictionaryRequestCellValue
  | ListCellFileModel;

export type ListRowValues = Record<ListColumnId, ListCellValues>;
export type ListRowModelValues = Record<ListColumnId, ListCellModelValues>;

export enum ListRowGroupType {
  Manual = 'manual',
  AutoValue = 'auto-value',
  AutoColumn = 'auto-column',
}

export interface ListModel
  extends CreateIssuerDateModel<UserProfileModel>,
    UpdateIssuerDateModel<UserProfileModel>,
    ReactableModel,
    CommentCountModel {
  id: ListId;
  name: string;
  description: string;
  slug: ListSlug;
  status: ListStatus;
  siteId: SiteId;
  permissions: SitesAllowedPermission[];
  notificationsSettings: ListNotificationsSettings;
}

export interface ListColumnModel extends CreateIssuerIdDateModel, UpdateIssuerIdDateModel {
  id: ListColumnId;
  listId: ListId;
  name: string;
  fieldType: ListColumnFieldType;
  fieldOptions: ListColumnFieldOptions;
  priority: number;
  isRequired: boolean;
  isVisible: boolean;
}

export type ListGroup = {
  id: ListGroupId;
  listId: ListId;
  name: string;
  groupType: ListRowGroupType;
  groupByColumnId?: ListColumnId;
  groupByRowId?: ListRowId;
};

export interface ListRowModel extends CreateIssuerIdDateModel, UpdateIssuerIdDateModel {
  id: ListRowId;
  listId: ListId;
  group: ListGroup | null;
  rowValues: ListRowModelValues;
}

export interface ListVirtualRow extends Pick<ListRowModel, 'id' | 'listId'> {
  rowValues: ListRowValues;
}
