import type { Rule, UiDatePickerRangeProps, UiDatePickerDisabledTimes } from '@vkph/ui';
import addYears from 'date-fns/addYears';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import isPast from 'date-fns/isPast';
import isSameDay from 'date-fns/isSameDay';
import isSameHour from 'date-fns/isSameHour';
import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import subYears from 'date-fns/subYears';
import range from 'lodash/range';

type DateInterval = {
  start?: Date;
  end?: Date;
};

export const MAX_BIRTHDAY_RANGE = 100;
export const MIN_BIRTHDAY_RANGE = 16;

export const dateBirthdayValidator = (date: Date) => {
  return (
    differenceInCalendarDays(subYears(new Date(), MAX_BIRTHDAY_RANGE), date) >= 0 ||
    differenceInCalendarDays(subYears(new Date(), MIN_BIRTHDAY_RANGE), date) <= 0
  );
};

export const disabledDateRangeUntil: (available?: DateInterval) => UiDatePickerRangeProps['disabledDate'] =
  (available?) => (date) => {
    const today = new Date();
    const start = startOfDay(available?.start || today);

    const end = available?.end || addYears(today, 100);

    return !isWithinInterval(date, { start, end });
  };

export const disabledDateRangeUntilToday = disabledDateRangeUntil();

export const disabledPastTime: UiDatePickerRangeProps['disabledTime'] = (date) => {
  if (isToday(date)) {
    const now = new Date();
    const nowHour = getHours(now);
    const selectedHour = getHours(date);
    const isMinutesDisabled = nowHour === selectedHour;

    return {
      disabledHours: () => range(24).splice(0, nowHour),
      disabledMinutes: () => range(60).splice(0, isMinutesDisabled ? getMinutes(now) : 0),
    };
  }

  return { disabledHours: () => [], disabledMinutes: () => [] };
};

const getDisabledPastHoursMinutes = (
  date: Date,
  selectedDate: Date,
  rangeType: 'start' | 'end',
): UiDatePickerDisabledTimes => {
  const hours = range(24);
  const minutes = range(60);
  const isMinutesDisabled = isSameHour(selectedDate, date);
  const minutesIndexStart = isMinutesDisabled ? getMinutes(selectedDate) : 0;
  const minutesIndexEnd = isMinutesDisabled ? getMinutes(selectedDate) : minutes.length;
  const isRangeStart = rangeType === 'start';
  const selectedHour = getHours(selectedDate);

  const disabledHours = isRangeStart
    ? hours.splice(0, selectedHour)
    : hours.splice(selectedHour + 1, hours.length);
  const disabledMinutes = isRangeStart
    ? minutes.splice(0, minutesIndexStart)
    : minutes.splice(minutesIndexEnd + 1, minutes.length);

  return {
    disabledHours: () => disabledHours,
    disabledMinutes: () => disabledMinutes,
  };
};

type DisabledTimeByExcludedDateRange = (available?: DateInterval) => UiDatePickerRangeProps['disabledTime'];

export const disabledTimeByExcludedDateRange: DisabledTimeByExcludedDateRange =
  (available) => (date, rangeType) => {
    let disabledTime: UiDatePickerDisabledTimes = { disabledHours: () => [], disabledMinutes: () => [] };

    if (rangeType === 'start' && available?.start && isSameDay(date, available?.start)) {
      disabledTime = getDisabledPastHoursMinutes(date, available?.start, rangeType);
    }

    if (rangeType === 'end' && available?.end && isSameDay(date, available?.end)) {
      disabledTime = getDisabledPastHoursMinutes(date, available?.end, rangeType);
    }

    return disabledTime;
  };

export const dateRangeStartAfterCurrentRule: Rule = {
  validator: (_, rangeDate) => {
    const startDate = rangeDate?.[0];

    return startDate && isPast(startDate) ? Promise.reject() : Promise.resolve();
  },
  required: true,
  message: 'Начальная дата не может быть в прошлом',
};

export const dateRangeEndLessThanToday: Rule = {
  validator: (_, [, endDate]: [Date, Date]) => {
    return !isPast(endDate) || isToday(endDate) ? Promise.resolve() : Promise.reject();
  },
  required: true,
  message: 'Конечная дата не может быть меньше текущей',
};
