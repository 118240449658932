export enum FileLogoVariants {
  EmptyState = 'emptyState',
  ImportantNews = 'importantNews',
  LogoDesktop = 'logoDesktop',
  LogoEmail = 'logoEmail',
  LogoMobile = 'logoMobile',
  PortalFavicon = 'portalFavicon',
  PortalHeader = 'portalHeader',
  UserAvatar = 'userAvatar',
}
