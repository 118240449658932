import { ContactLink } from './contactLinks.model';
import { FileStorageEntryId } from './file.model';
import type { KeycloakId, KeycloakUserModel } from './keycloakUser.model';

export enum Gender {
  Female = '0',
  Male = '1',
}

export type UserIdModel<T = KeycloakId> = T;
export type UserAvatarModel = {
  avatar?: string;
  smallAvatar?: string;
  mediumAvatar?: string;
};

export type UserPositionId = string;

export type UserPositionModel = {
  id: UserPositionId;
  name: string;
};

interface UserIdModelRequired {
  keycloakId: UserIdModel;
}

export interface UserModel extends Partial<UserAvatarModel>, Partial<UserIdModelRequired> {
  id: UserIdModel;
  username?: string;
  email?: string;
  patronymic?: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  avatar?: string;
  smallAvatar?: string;
  keycloakUser?: KeycloakUserModel;
  position?: UserPositionModel;
  isActive?: boolean;
}

export type UserModelIdRequired = UserIdModelRequired & Omit<UserModel, 'keycloakId'>;

export type FullNameModel = {
  firstName: string;
  lastName: string;
  patronymic: string;
};

type UserProfileContactLinks = {
  externalLinks: ContactLink[];
  otherContacts: ContactLink[];
  otherLinks: ContactLink[];
};

type UserProfileContact = {
  mobilePhone: string | null;
  personalEmail: string | null;
  workAddress: string | null;
  workEmail: string | null;
  workMessenger: string | null;
  workMobilePhone: string | null;
  workModeRid: string | null;
  workMode: string | null;
  workPhone?: string | null;
  workPhoneExtension: string | null;
};

export interface BaseUserProfileModel extends UserAvatarModel, Pick<UserProfileContact, 'workPhone'> {
  id: UserIdModel;
  fullName: FullNameModel;
  gender: Gender;
  actualAddress: string;
  email: string;
  superAdmin: boolean;
  position?: UserPositionModel;
}

export interface UserProfileModel extends BaseUserProfileModel, UserProfileContact {
  groupPath: string | null;
  absence: {
    begin: string;
    cause: string;
    end: string;
  };
  birthDate: string;
  birthDayMonth: string;
  birthYear: number;
  clothesSize: string;
  clothesSizeRid: string | null;
  colleague: boolean | null;
  eyeColor: string;
  eyeColorRid: string | null;
  isBirthYearHidden?: boolean;
  follow: boolean;
  footSize: number | string;
  footSizeRid: string | null;
  height: string;
  startOfWork: string;
  townLive?: string;
  townLiveRid: string | null;
  metroStation?: string;
  metroStationRid: string | null;
  timezone: string;
  remoteEmployee: boolean;
  memberOf: string[];
  isActive?: boolean;
  blockedAt?: string;
  fileStorageRootFolderId?: FileStorageEntryId;
  contacts?: UserProfileContactLinks;
  assistants: UserModel[];
  keycloakId: UserIdModel;
}

export interface BaseJobModel {
  position?: UserPositionModel;
}

export interface JobModel extends BaseJobModel {
  pk: string;
  dateStart: string;
  dateEnd: string;
  user: string;
  remoteEmployee: boolean;
}

export type BossModel = {
  avatar?: string;
  colleague: string;
  follow: string;
  fullName: FullNameModel;
  id: string;
  job: JobModel;
  workEmail: string;
  workPhone: string;
};

export type UserJobModel = {
  boss: BossModel;
  user: string;
} & JobModel;

export type UserProfileJobModel = {
  job: JobModel;
} & UserProfileModel;

export type UserInitiatorModel = {
  username: string;
  firstName: string;
  lastName: string;
  keycloakId: UserIdModel;
  fullName: FullNameModel;
  gender?: Gender;
  isActive: boolean;
};

export type UserAuthorModel = Pick<
  UserProfileJobModel,
  'id' | 'avatar' | 'fullName' | 'smallAvatar' | 'mediumAvatar' | 'job' | 'isActive'
>;
