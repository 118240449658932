import {
  BlogId,
  BlogSlugId,
  FileStorageContextServiceTypes,
  FileStorageEntryId,
  FileStorageEntryType,
  FileStorageListEntryModel,
  FileStorageSlug,
  ProjectId,
  SiteServiceType,
  SiteSlug,
  UserIdModel,
} from '../../types/models';

type FileStorageRouteParams = {
  id?: BlogId | BlogSlugId | ProjectId | UserIdModel;
  slug?: SiteSlug;
  serviceType?: SiteServiceType;
  serviceSlug?: FileStorageSlug;
  entry?: 'folder' | 'file';
  entryId?: FileStorageEntryId;
};

export const getFileStorageRouteParams = (
  entry: FileStorageListEntryModel,
): FileStorageRouteParams | null => {
  if (entry?.context?.service === FileStorageContextServiceTypes.Blogs) {
    return { id: entry.context.blogSlug || entry.context.blogId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Projects) {
    return { id: entry.context.projectId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Profile) {
    return { id: entry.context.userId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Sites) {
    return {
      slug: entry.context.siteSlug,
      serviceType: SiteServiceType.FileStorage,
      serviceSlug: entry?.siteFilestorage?.slug,
      entry: entry.type === FileStorageEntryType.Folder ? 'folder' : 'file',
      entryId: entry.id,
    };
  }

  return null;
};
